import { defineMessages } from "react-intl";

export const transtionsAttributesPageFieldsMessages = defineMessages({
  values: {
    id: "JE0TAx",
    defaultMessage: "Values",
    description: "section name",
  },
  valueNumber: {
    id: "UvD+xp",
    defaultMessage: "Value {number}",
    description: "attribute values",
  },
  description: {
    id: "YY4ENH",
    defaultMessage: "Description",
    description: "description section name",
  },
  descriptionNumber: {
    id: "CD8L62",
    defaultMessage: "Description {number}",
    description: "attribute values",
  },
});
