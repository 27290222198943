import { makeStyles } from "@saleor/macaw-ui";

export const useStyles = makeStyles(
  () => ({
    loadingSpinner: {
      display: "flex",
      justifyContent: "center",
    },
    dialog: {
      width: "400px",
    },
    list: {
      width: "100%",
      maxHeight: "80vh",
      overflow: "auto",
    },
  }),
  { name: "OrdersListPage" },
);
