import BackButton from "@dashboard/components/BackButton";
import { useStyles } from "@dashboard/orders/components/OrderListPage/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@material-ui/core";
import { Spinner } from "@saleor/macaw-ui/next";
import React from "react";
import { FormattedMessage } from "react-intl";

interface OrdersRouteDialogProps {
  loading: boolean;
  open: boolean;
  onClose: () => void;
  items: string[];
}

const OrdersRouteDialog: React.FC<OrdersRouteDialogProps> = props => {
  const { loading, open, onClose, items } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage
          id="rLx4WW"
          defaultMessage="Route"
          description="dialog header"
        />
      </DialogTitle>

      <DialogContent className={classes.dialog}>
        <div>
          {(() => {
            if (loading) {
              return (
                <div className={classes.loadingSpinner}>
                  <Spinner />
                </div>
              );
            }

            if (!items.length) {
              return (
                <FormattedMessage
                  id="Vladrz"
                  defaultMessage="Route is not available at the moment. Please try again later."
                  description="empty route response"
                />
              );
            }

            return (
              <List className={classes.list}>
                {items.map((item, index) => (
                  <ListItem key={index}>{item}</ListItem>
                ))}
              </List>
            );
          })()}
        </div>
      </DialogContent>

      <DialogActions>
        <BackButton onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default OrdersRouteDialog;
