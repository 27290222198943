import { defineMessages } from "react-intl";

export const messages = defineMessages({
  productOwnerDetailsTitle: {
    id: "81WtS3",
    defaultMessage: "Product owner details",
  },
  email: {
    id: "uPNlBw",
    defaultMessage: "Email: {email}",
  },
  phone: {
    id: "+g1/+e",
    defaultMessage: "Phone: {phone}",
  },
});
