import { DashboardCard } from "@dashboard/components/Card";
import { messages } from "@dashboard/products/components/ProductOwner/messages";
import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

interface ProductOwnerProps {
  email: string | undefined;
  phone: string | undefined;
  isLoading: boolean;
}

const ProductOwner: React.FC<ProductOwnerProps> = props => {
  const intl = useIntl();

  return (
    <DashboardCard>
      <DashboardCard.Title>
        {intl.formatMessage(messages.productOwnerDetailsTitle)}
      </DashboardCard.Title>

      <DashboardCard.Content display="flex" flexDirection="column" gap={2}>
        {(() => {
          if (props.isLoading) {
            return <CircularProgress size={20} />;
          }

          return (
            <>
              <Typography>
                {intl.formatMessage(messages.email, {
                  email: props.email || "-",
                })}
              </Typography>
              <Typography>
                {intl.formatMessage(messages.phone, {
                  phone: props.phone || "-",
                })}
              </Typography>
            </>
          );
        })()}
      </DashboardCard.Content>
    </DashboardCard>
  );
};

ProductOwner.displayName = "ProductOwner";
export default ProductOwner;
