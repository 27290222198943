// @ts-strict-ignore
import { categoryListUrl, categoryUrl } from "@dashboard/categories/urls";
import { TopNav } from "@dashboard/components/AppLayout";
import { DashboardCard } from "@dashboard/components/Card";
import { CardSpacer } from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { Metadata } from "@dashboard/components/Metadata/Metadata";
import Savebar from "@dashboard/components/Savebar";
import { SeoForm } from "@dashboard/components/SeoForm";
import { Tab, TabContainer } from "@dashboard/components/Tab";
import VerticalSpacer from "@dashboard/components/VerticalSpacer";
import { DEFAULT_INITIAL_SEARCH_DATA } from "@dashboard/config";
import {
  CategoryDetailsQuery,
  ProductErrorFragment,
  useCategoryUpdateMutation,
} from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import ProductTypePickerDialog from "@dashboard/products/components/ProductTypePickerDialog";
import useProductTypeSearch from "@dashboard/searches/useProductTypeSearch";
import { mapEdgesToItems, mapNodeToChoice } from "@dashboard/utils/maps";
import { Box } from "@material-ui/core";
import { Button } from "@saleor/macaw-ui";
import { sprinkles, Text } from "@saleor/macaw-ui/next";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { maybe } from "../../../misc";
import { ListProps, ListViews, RelayToFlat } from "../../../types";
import CategoryDetailsForm from "../../components/CategoryDetailsForm";
import CategoryBackground from "../CategoryBackground";
import { CategoryProducts } from "../CategoryProducts";
import { CategorySubcategories } from "../CategorySubcategories";
import CategoryUpdateForm, { CategoryUpdateData } from "./form";

export enum CategoryPageTab {
  categories = "categories",
  products = "products",
}

export interface CategoryUpdatePageProps
  extends Pick<
    ListProps<ListViews.CATEGORY_LIST>,
    "onUpdateListSettings" | "settings"
  > {
  categoryId: string;
  changeTab: (index: CategoryPageTab) => void;
  currentTab: CategoryPageTab;
  errors: ProductErrorFragment[];
  disabled: boolean;
  category: CategoryDetailsQuery["category"];
  products: RelayToFlat<CategoryDetailsQuery["category"]["products"]>;
  subcategories: RelayToFlat<CategoryDetailsQuery["category"]["children"]>;
  saveButtonBarState: ConfirmButtonTransitionState;
  addProductHref: string;
  onImageDelete: () => void;
  onSubmit: (data: CategoryUpdateData) => SubmitPromise;
  onCategoriesDelete: () => void;
  onProductsDelete: () => void;
  onSelectProductsIds: (ids: number[], clearSelection: () => void) => void;
  onSelectCategoriesIds: (ids: number[], clearSelection: () => void) => void;
  onImageUpload: (file: File) => any;
  onDelete: () => any;
}

const CategoriesTab = Tab(CategoryPageTab.categories);
const ProductsTab = Tab(CategoryPageTab.products);

export const CategoryUpdatePage: React.FC<CategoryUpdatePageProps> = ({
  categoryId,
  changeTab,
  currentTab,
  category,
  disabled,
  errors,
  products,
  saveButtonBarState,
  subcategories,
  onDelete,
  onSubmit,
  onImageDelete,
  onImageUpload,
  onSelectCategoriesIds,
  onCategoriesDelete,
  onProductsDelete,
  onSelectProductsIds,
  settings,
  onUpdateListSettings,
}: CategoryUpdatePageProps) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const notify = useNotifier();
  const [openProductTypeDialog, setOpenProductTypeDialog] = useState(false);

  const backHref = category?.parent?.id
    ? categoryUrl(category?.parent?.id)
    : categoryListUrl();

  const {
    loadMore: loadMoreDialogProductTypes,
    search: searchDialogProductTypes,
    result: searchDialogProductTypesOpts,
  } = useProductTypeSearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });

  const fetchMoreDialogProductTypes = {
    hasMore: searchDialogProductTypesOpts.data?.search?.pageInfo?.hasNextPage,
    loading: searchDialogProductTypesOpts.loading,
    onFetchMore: loadMoreDialogProductTypes,
  };

  const [updateCategory] = useCategoryUpdateMutation({
    onCompleted: () => {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "n8L7vC",
          defaultMessage: "Product type added",
        }),
      });
    },
    onError: () => {
      notify({
        status: "error",
        text: intl.formatMessage({
          id: "8AIHMk",
          defaultMessage: "Failed to add product type",
        }),
      });
    },
  });

  return (
    <CategoryUpdateForm
      category={category}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {({ data, change, handlers, submit, isSaveDisabled }) => (
        <DetailPageLayout gridTemplateColumns={1}>
          <TopNav href={backHref} title={category?.name} />
          <DetailPageLayout.Content>
            <CategoryDetailsForm
              data={data}
              disabled={disabled}
              errors={errors}
              onChange={change}
            />

            {subcategories && !subcategories.length && (
              <DashboardCard>
                <DashboardCard.Title>
                  <FormattedMessage
                    id="k0mjVb"
                    defaultMessage="Product type"
                    description="product type card title"
                  />
                </DashboardCard.Title>
                <DashboardCard.Content>
                  <Box>
                    {category.productType ? (
                      <Text>{category.productType.name}</Text>
                    ) : (
                      <FormattedMessage
                        id="Uc51zH"
                        defaultMessage="Product type not selected"
                        description="empty product type value"
                      />
                    )}
                  </Box>
                  <VerticalSpacer spacing={3} />
                  <Button
                    variant="secondary"
                    onClick={() => setOpenProductTypeDialog(true)}
                    type="button"
                  >
                    {!!category.productType ? (
                      <FormattedMessage
                        id="wlW7y5"
                        defaultMessage="Update product type"
                        description="category product type update label"
                      />
                    ) : (
                      <FormattedMessage
                        id="6WlMJD"
                        defaultMessage="Add product type"
                        description="category product type add label"
                      />
                    )}
                  </Button>
                </DashboardCard.Content>
                <ProductTypePickerDialog
                  confirmButtonState="success"
                  open={openProductTypeDialog}
                  productTypes={mapNodeToChoice(
                    mapEdgesToItems(searchDialogProductTypesOpts?.data?.search),
                  )}
                  fetchProductTypes={searchDialogProductTypes}
                  fetchMoreProductTypes={fetchMoreDialogProductTypes}
                  onClose={() => setOpenProductTypeDialog(false)}
                  onConfirm={async productTypeId => {
                    await updateCategory({
                      variables: {
                        id: categoryId,
                        input: {
                          productType: productTypeId,
                        },
                      },
                    });

                    setOpenProductTypeDialog(false);
                  }}
                />
              </DashboardCard>
            )}

            <CardSpacer />

            <CategoryBackground
              data={data}
              onImageUpload={onImageUpload}
              onImageDelete={onImageDelete}
              image={maybe(() => category.backgroundImage)}
              onChange={change}
            />

            <CardSpacer />

            <SeoForm
              helperText={intl.formatMessage({
                id: "wQdR8M",
                defaultMessage:
                  "Add search engine title and description to make this category easier to find",
              })}
              errors={errors}
              title={data.seoTitle}
              titlePlaceholder={data.name}
              description={data.seoDescription}
              descriptionPlaceholder={data.name}
              slug={data.slug}
              slugPlaceholder={data.name}
              loading={!category}
              onChange={change}
              disabled={disabled}
            />

            <CardSpacer />

            <Metadata data={data} onChange={handlers.changeMetadata} />

            <CardSpacer />

            <TabContainer className={sprinkles({ paddingX: 9 })}>
              <CategoriesTab
                isActive={currentTab === CategoryPageTab.categories}
                changeTab={changeTab}
              >
                <FormattedMessage
                  id="JDz5h8"
                  defaultMessage="Subcategories"
                  description="number of subcategories in category"
                />
              </CategoriesTab>

              <ProductsTab
                testId="products-tab"
                isActive={currentTab === CategoryPageTab.products}
                changeTab={changeTab}
              >
                <FormattedMessage
                  id="V+fkAO"
                  defaultMessage="Products"
                  description="number of products in category"
                />
              </ProductsTab>
            </TabContainer>

            <CardSpacer />

            {currentTab === CategoryPageTab.categories && (
              <CategorySubcategories
                disabled={disabled}
                onUpdateListSettings={onUpdateListSettings}
                settings={settings}
                subcategories={subcategories}
                onCategoriesDelete={onCategoriesDelete}
                onSelectCategoriesIds={onSelectCategoriesIds}
                categoryId={categoryId}
              />
            )}

            {currentTab === CategoryPageTab.products && (
              <CategoryProducts
                category={category}
                categoryId={categoryId}
                products={products}
                disabled={disabled}
                onProductsDelete={onProductsDelete}
                onSelectProductsIds={onSelectProductsIds}
              />
            )}

            <Savebar
              onCancel={() => {
                if (!subcategories.length && !category.productType.id) {
                  notify({
                    status: "error",
                    text: intl.formatMessage({
                      id: "6q3Pis",
                      defaultMessage: "Please select product type",
                    }),
                  });
                  return;
                }

                navigate(backHref);
              }}
              onDelete={onDelete}
              onSubmit={submit}
              state={saveButtonBarState}
              disabled={
                isSaveDisabled ||
                (!subcategories.length && !category.productType)
              }
            />
          </DetailPageLayout.Content>
        </DetailPageLayout>
      )}
    </CategoryUpdateForm>
  );
};
CategoryUpdatePage.displayName = "CategoryUpdatePage";
export default CategoryUpdatePage;
