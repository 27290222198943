// @ts-strict-ignore
import {
  loadingCell,
  moneyCell,
  readonlyTextCell,
  thumbnailCell,
} from "@dashboard/components/Datagrid/customCells/cells";
import { GetCellContentOpts } from "@dashboard/components/Datagrid/Datagrid";
import { AvailableColumn } from "@dashboard/components/Datagrid/types";
import { CustomerDetailsFragment, OrderLineFragment } from "@dashboard/graphql";
import { getDatagridRowDataIndex } from "@dashboard/misc";
import { GridCell, Item } from "@glideapps/glide-data-grid";
import { IntlShape } from "react-intl";

import { columnsMessages } from "./messages";

export const orderDetailsStaticColumnsAdapter = (
  intl: IntlShape,
): AvailableColumn[] => [
  {
    id: "product",
    title: intl.formatMessage(columnsMessages.product),
    width: 200,
  },
  {
    id: "sku",
    title: intl.formatMessage(columnsMessages.sku),
    width: 150,
  },
  {
    id: "variantName",
    title: intl.formatMessage(columnsMessages.variantName),
    width: 150,
  },
  {
    id: "quantity",
    title: intl.formatMessage(columnsMessages.quantity),
    width: 80,
  },
  {
    id: "price",
    title: intl.formatMessage(columnsMessages.price),
    width: 150,
  },
  {
    id: "total",
    title: intl.formatMessage(columnsMessages.total),
    width: 150,
  },
  {
    id: "sellersPhoneNumber",
    title: intl.formatMessage(columnsMessages.sellersPhoneNumber),
    width: 150,
  },
  {
    id: "pickUpAddress",
    title: intl.formatMessage(columnsMessages.pickUpAddress),
    width: 300,
  },
];

interface GetCellContentProps {
  columns: AvailableColumn[];
  data: Array<
    Pick<
      OrderLineFragment,
      | "productSku"
      | "productName"
      | "thumbnail"
      | "variant"
      | "quantity"
      | "unitPrice"
      | "totalPrice"
    > &
      Pick<CustomerDetailsFragment, "phoneNumber" | "defaultShippingAddress">
  >;
  loading: boolean;
}

export const createGetCellContent =
  ({ columns, data, loading }: GetCellContentProps) =>
  ([column, row]: Item, { added, removed }: GetCellContentOpts): GridCell => {
    if (loading) {
      return loadingCell();
    }

    const columnId = columns[column]?.id;

    const rowData = added.includes(row)
      ? undefined
      : data[getDatagridRowDataIndex(row, removed)];

    if (!rowData || !columnId) {
      return readonlyTextCell("", false);
    }

    switch (columnId) {
      case "product":
        return thumbnailCell(
          rowData?.productName ?? "",
          rowData.thumbnail?.url ?? "",
          readonyOptions,
        );
      case "sku":
        return readonlyTextCell(rowData.productSku ?? "", false);
      case "variantName":
        return readonlyTextCell(rowData?.variant?.name ?? "-", false);
      case "quantity":
        return readonlyTextCell(rowData.quantity.toString(), false);
      case "price":
        return moneyCell(
          rowData.unitPrice.gross.amount,
          rowData.unitPrice.gross.currency,
          readonyOptions,
        );
      case "total":
        return moneyCell(
          rowData.totalPrice.gross.amount,
          rowData.totalPrice.gross.currency,
          readonyOptions,
        );
      case "sellersPhoneNumber":
        return readonlyTextCell(rowData.phoneNumber || "-", false);
      case "pickUpAddress":
        return readonlyTextCell(
          rowData.defaultShippingAddress
            ? [
                rowData.defaultShippingAddress.streetAddress1,
                rowData.defaultShippingAddress.streetAddress2,
                rowData.defaultShippingAddress.postalCode,
                rowData.defaultShippingAddress.city,
              ]
                .filter(fieldValue => !!fieldValue)
                .join(", ")
            : "-",
          false,
        );
      default:
        return readonlyTextCell("", false);
    }
  };

const readonyOptions: Partial<GridCell> = {
  allowOverlay: false,
  readonly: true,
};
