// @ts-strict-ignore
import { gql } from "@apollo/client";
import {
  SearchCategoriesDocument,
  SearchCategoriesQuery,
  SearchCategoriesQueryVariables,
} from "@dashboard/graphql";
import makeTopLevelSearch from "@dashboard/hooks/makeTopLevelSearch";

export const searchCategories = gql`
  query SearchCategories(
    $after: String
    $first: Int!
    $query: String!
    $productTypeId: [ID!]
  ) {
    search: categories(
      after: $after
      first: $first
      filter: { search: $query, productType: $productTypeId }
    ) {
      edges {
        node {
          id
          name
          ancestors(first: 1000) {
            edges {
              node {
                name
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export default makeTopLevelSearch<
  SearchCategoriesQuery,
  SearchCategoriesQueryVariables
>(SearchCategoriesDocument);
